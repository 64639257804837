import HomeView from '../views/HomeView.vue'
import PhoneHome from '../views/phoneHome.vue'
/**
 * 基础路由
 * @type { *[] }
 */
export const addRouterMap = [
  {
    path: '/login',
    name: 'login',
    meta: { 
      requireAuth: false,
      title: '登录'
    },
    roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin', 'rewardAdmin'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: { requireAuth: true },  // 是否校验登录
    component: HomeView,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin', 'rewardAdmin'],
        component: () => import('@/views/dashboard.vue'),
        meta: { title: 'dashboard', requireAuth: true, isChild: false, roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin', 'rewardAdmin'] },
      },
      {
        path: '/warehouseManage',
        name: 'warehouseManage',
        roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin'],
        meta: { title: '仓库管理', requireAuth: true, isChild: true, roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin'] },
        children: [
          {
            path: '/warehouseManage/liveRoomList',
            name: 'liveRoomList',
            roles: ['superAdmin', 'buyAdmin'],
            component: () => import('@/views/warehouseManage/liveRoomList.vue'),
            meta: { title: '直播间管理', requireAuth: true, roles: ['superAdmin', 'buyAdmin'] }
          },
          {
            path: '/warehouseManage/warehouseList',
            name: 'warehouseList',
            roles: ['superAdmin', 'roomAdmin'],
            component: () => import('@/views/warehouseManage/warehouseList.vue'),
            meta: { title: '仓库列表', requireAuth: true, roles: ['superAdmin', 'roomAdmin'] }
          },
          {
            path: '/warehouseManage/warehouseIPList',
            name: 'warehouseIPList',
            roles: ['superAdmin', 'roomAdmin', 'buyAdmin'],
            component: () => import('@/views/warehouseManage/warehouseIPList.vue'),
            meta: { title: 'IP库', requireAuth: true, roles: ['superAdmin', 'roomAdmin', 'buyAdmin'] }
          },
          {
            path: '/warehouseManage/warehouseSeriesList',
            name: 'warehouseSeriesList',
            roles: ['superAdmin', 'roomAdmin', 'buyAdmin'],
            component: () => import('@/views/warehouseManage/warehouseSeriesList.vue'),
            meta: { title: '系列库', requireAuth: true, roles: ['superAdmin', 'roomAdmin', 'buyAdmin'] }
          },
          {
            path: '/warehouseManage/warehouseGoodsList',
            name: 'warehouseGoodsList',
            roles: ['superAdmin', 'roomAdmin', 'buyAdmin'],
            component: () => import('@/views/warehouseManage/warehouseGoodsList.vue'),
            meta: { title: '商品列表', requireAuth: true, roles: ['superAdmin', 'roomAdmin', 'buyAdmin'] }
          },
          {
            path: '/warehouseManage/warehouseNumber',
            name: 'warehouseNumber',
            roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin'],
            component: () => import('@/views/warehouseManage/warehouseNumber.vue'),
            meta: { title: '库存列表', requireAuth: true, roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin'] }
          },
          {
            path: '/warehouseManage/purchaseOrderList',
            name: 'purchaseOrderList',
            roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin'],
            component: () => import('@/views/warehouseManage/purchaseOrderList.vue'),
            meta: { title: '采购单管理', requireAuth: true, roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin'] }
          },
        ]
      },
      {
        path: '/rewardManage',
        name: 'rewardManage',
        roles: ['superAdmin', 'rewardAdmin'],
        meta: { title: '一番赏管理', requireAuth: true, isChild: true, roles: ['superAdmin', 'rewardAdmin'] },
        children: [
          {
            path: '/rewardManage/rewardIPList',
            name: 'rewardIPList',
            component: () => import('@/views/rewardManage/rewardIPList.vue'),
            meta: { title: '商品IP管理', requireAuth: true }
          },
          {
            path: '/rewardManage/rewardGoodsList',
            name: 'rewardGoodsList',
            component: () => import('@/views/rewardManage/rewardGoodsList.vue'),
            meta: { title: '商品管理', requireAuth: true }
          },
          {
            path: '/rewardManage/rewardStoreList',
            name: 'rewardStoreList',
            component: () => import('@/views/rewardManage/rewardStoreList.vue'),
            meta: { title: '店铺管理', requireAuth: true }
          },
          {
            path: '/rewardManage/rewardSKUList',
            name: 'rewardSKUList',
            component: () => import('@/views/rewardManage/rewardSKUList.vue'),
            meta: { title: 'SKU管理', requireAuth: true }
          },
        ]
      },
      {
        path: '/cooperationList',
        name: 'cooperationList',
        roles: ['superAdmin', 'rewardAdmin', 'roomAdmin'],
        component: () => import('@/views/cooperationManage/cooperationList.vue'),
        meta: { title: '博主合作', requireAuth: true, isChild: false, roles: ['superAdmin', 'rewardAdmin', 'roomAdmin'] },
      },
      {
        path: '/accountList',
        name: 'accountList',
        roles: ['superAdmin'],
        component: () => import('@/views/accountManage/accountList.vue'),
        meta: { title: '账户管理', requireAuth: true, isChild: false, roles: ['superAdmin'] },
      },
      {
        path: '/logList',
        name: 'logList',
        roles: ['superAdmin'],
        component: () => import('@/views/logManage/logList.vue'),
        meta: { title: '日志管理', requireAuth: true, isChild: false, roles: ['superAdmin']  },
      },
    ]
  },
  {
    path: '/phoneHome',
    name: 'homePage',
    meta: { requireAuth: true },  // 是否校验登录
    component: PhoneHome,
    redirect: '/homePage',
    children: [
      {
        path: '/homePage',
        name: 'homePage',
        roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin', 'rewardAdmin'],
        component: () => import('@/views/phoneManage/homePage.vue'),
        meta: { requireAuth: true, isChild: false, roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin', 'rewardAdmin'] },
      },
      {
        path: '/deleteRoomPage',
        name: 'deleteRoomPage',
        roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin', 'rewardAdmin'],
        component: () => import('@/views/phoneManage/deleteRoomPage.vue'),
        meta: { requireAuth: true, isChild: false, roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin', 'rewardAdmin'] },
      },
      {
        path: '/chooseGoodsPage',
        name: 'chooseGoodsPage',
        roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin', 'rewardAdmin'],
        component: () => import('@/views/phoneManage/chooseGoodsPage.vue'),
        meta: { requireAuth: true, isChild: false, roles: ['superAdmin', 'roomAdmin', 'buyAdmin', 'liveAdmin', 'rewardAdmin'] },
      }
    ]
  }
]
