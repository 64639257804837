import axios from 'axios'
import { VueAxios } from './axios'
import store from '@/store'
import { message } from 'ant-design-vue';
let apiTestUrl = 'https://cytapi.vipkh.cn/api/'
let apiUrl = 'https://cyapi.vipkh.cn/api/'
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: apiTestUrl,
  timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = localStorage.getItem('Access-Token');
    if (error.response.status === 403) {
      message.error(data.message);
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      message.error('Authorization verification failed');
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
    // 缺少参数
    if (error.response.status === 421) {
      message.error(data.message);
    }
    // 登录过期
    if (error.response.status === 422) {
      message.error(data.message);
      store.dispatch('Logout').then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1500)
      })
    }
    // 错误提示
    if (error.response.status === 500) {
      message.error(data.message);
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = localStorage.getItem('Access-Token');
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Authorization'] = token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data.data) return response.data
  else {
    message.error(response.data.message);
  }
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
